import React, { useState } from "react";
import Header from "../components/Header/Header";
import Included from "../components/Included/Included";
import Benefit from "../components/Benefit-2/Benefit";
import L2R1 from "../components/L2R1/L2R1";
import { Link } from "react-router-dom";
import Footer from "../components/Footer/Footer";

import audit from "../images/audit.png";
import check from "../images/check.png";
import guidance from "../images/guidance.png";
import large from "../images/large.png";
import logo from "../images/logo.png";
import maximize from "../images/maximize.png";
import medium from "../images/medium.png";
import perfect from "../images/perfect.png";
import small from "../images/small.png";
import time from "../images/time.png";

import FinanceTracker from "../components/Resource/FinanceTracker";
import Intro from "../sections/Intro";

import Bullet from "../components/Bullet/Bullet";

const Bookkeeping = () => {
  const [eCount, setECount] = useState(0);
  const [pCount, setPCount] = useState(0);

  return (
    <div className="page">
      <Header />
      <Intro
        primary="We'll look after your books."
        secondary="Delegate day-to-day bookkeeping tasks, giving yourself more time to do
          what you love."
        image={2}
      />
      <div className="section background">
        <h2>See what's included.</h2>

        <p>
          We understand that every business is different, which is why we offer
          two tailored bookkeeping packages designed to meet you where you are.
        </p>
        <p>
          Our{" "}
          <strong style={{ color: "#886aff" }}>Essentials Package (E)</strong>{" "}
          is perfect for small businesses just starting out, providing you with
          the necessary tools to stay organized and manage your finances
          effectively.
        </p>
        <p>
          Our <strong style={{ color: "#886aff" }}>Premium Package (P)</strong>{" "}
          is for more established companies, providing you with the expert help,
          tools, and resources you need to scale with confidence.
        </p>
        <p>Check out the features unique to each package:</p>

        <h3>Accounting Maintenance</h3>

        <div className="comparison">
          <div className="crow cheader">
            <p className="comcol1" style={{ color: "white" }}>
              Service
            </p>
            <p className="comcol2 ssentials" style={{ color: "white" }}>
              E
            </p>
            <p className="comcol2 remium" style={{ color: "white" }}>
              P
            </p>
          </div>
          <div className="crow">
            <p className="comcol1">Weekly Transaction Entry / Bookkeeping</p>
            <div className="comcol2">
              <img className="check" src={check} alt="check" />
            </div>
            <div className="comcol2">
              <img className="check" src={check} alt="check" />
            </div>
          </div>
          <div className="crow background">
            <p className="comcol1">Class Tracking</p>
            <div className="comcol2"></div>
            <div className="comcol2">
              <img className="check" src={check} alt="check" />
            </div>
          </div>
          <div className="crow">
            <p className="comcol1">Job / Customer Tracking</p>
            <div className="comcol2"></div>
            <div className="comcol2">
              <img className="check" src={check} alt="check" />
            </div>
          </div>
          <div className="crow background">
            <p className="comcol1">Detailed Payroll Journal Entries</p>
            <div className="comcol2"></div>
            <div className="comcol2">
              <img className="check" src={check} alt="check" />
            </div>
          </div>
          <div className="crow">
            <p className="comcol1">Monthly Account Reconciliations</p>
            <div className="comcol2">
              <img className="check" src={check} alt="check" />
            </div>
            <div className="comcol2">
              <img className="check" src={check} alt="check" />
            </div>
          </div>
          <div className="crow background">
            <p className="comcol1">Limited A/R, A/P, and Inventory Services</p>
            <div className="comcol2"></div>
            <div className="comcol2">
              <img className="check" src={check} alt="check" />
            </div>
          </div>
          <div className="crow">
            <p className="comcol1">QuickBooks Online Account Maintenance</p>
            <div className="comcol2">
              <img className="check" src={check} alt="check" />
            </div>
            <div className="comcol2">
              <img className="check" src={check} alt="check" />
            </div>
          </div>
          <div className="crow background">
            <p className="comcol1">Sales and Use Tax Reporting</p>
            <div className="comcol2">
              <img className="check" src={check} alt="check" />
            </div>
            <div className="comcol2">
              <img className="check" src={check} alt="check" />
            </div>
          </div>
        </div>

        <h3>Monthly Comprehensive Financial Reports</h3>

        <div className="comparison">
          <div className="crow cheader">
            <p className="comcol1" style={{ color: "white" }}>
              Report
            </p>
            <p className="comcol2 ssentials" style={{ color: "white" }}>
              E
            </p>
            <p className="comcol2 remium" style={{ color: "white" }}>
              P
            </p>
          </div>
          <div className="crow">
            <p className="comcol1">Balance Sheet</p>
            <div className="comcol2">
              <img className="check" src={check} alt="check" />
            </div>
            <div className="comcol2">
              <img className="check" src={check} alt="check" />
            </div>
          </div>
          <div className="crow background">
            <p className="comcol1">Profit & Loss</p>
            <div className="comcol2">
              <img className="check" src={check} alt="check" />
            </div>
            <div className="comcol2">
              <img className="check" src={check} alt="check" />
            </div>
          </div>
          <div className="crow">
            <p className="comcol1">Bank Reconciliation Details</p>
            <div className="comcol2">
              <img className="check" src={check} alt="check" />
            </div>
            <div className="comcol2">
              <img className="check" src={check} alt="check" />
            </div>
          </div>
          <div className="crow background">
            <p className="comcol1">Amortization Schedule</p>
            <div className="comcol2">
              <img className="check" src={check} alt="check" />
            </div>
            <div className="comcol2">
              <img className="check" src={check} alt="check" />
            </div>
          </div>
          <div className="crow">
            <p className="comcol1">Depreciation Schedule</p>
            <div className="comcol2">
              <img className="check" src={check} alt="check" />
            </div>
            <div className="comcol2">
              <img className="check" src={check} alt="check" />
            </div>
          </div>
          <div className="crow background">
            <p className="comcol1">Distribution Schedule</p>
            <div className="comcol2">
              <img className="check" src={check} alt="check" />
            </div>
            <div className="comcol2">
              <img className="check" src={check} alt="check" />
            </div>
          </div>
        </div>

        <h3>Monthly Forecasting</h3>

        <div className="comparison">
          <div className="crow cheader">
            <p className="comcol1" style={{ color: "white" }}>
              Forecast
            </p>
            <p className="comcol2 ssentials" style={{ color: "white" }}>
              E
            </p>
            <p className="comcol2 remium" style={{ color: "white" }}>
              P
            </p>
          </div>
          <div className="crow">
            <p className="comcol1">Weekly Cash Flow Forecast</p>
            <div className="comcol2"></div>
            <div className="comcol2">
              <img className="check" src={check} alt="check" />
            </div>
          </div>
          <div className="crow background">
            <p className="comcol1">Monthly Balance Sheet Forecast</p>
            <div className="comcol2"></div>
            <div className="comcol2">
              <img className="check" src={check} alt="check" />
            </div>
          </div>
          <div className="crow">
            <p className="comcol1">Monthly Profit & Loss Forecast</p>
            <div className="comcol2"></div>
            <div className="comcol2">
              <img className="check" src={check} alt="check" />
            </div>
          </div>
        </div>

        <h3>Monthly Consulting Meeting</h3>

        <div className="comparison">
          <div className="crow cheader">
            <p className="comcol1" style={{ color: "white" }}>
              Benefit
            </p>
            <p className="comcol2 ssentials" style={{ color: "white" }}>
              E
            </p>
            <p className="comcol2 remium" style={{ color: "white" }}>
              P
            </p>
          </div>
          <div className="crow">
            <p className="comcol1">Identify Prosperous Trends</p>
            <div className="comcol2"></div>
            <div className="comcol2">
              <img className="check" src={check} alt="check" />
            </div>
          </div>
          <div className="crow background">
            <p className="comcol1">Monitor Financial Goals</p>
            <div className="comcol2"></div>
            <div className="comcol2">
              <img className="check" src={check} alt="check" />
            </div>
          </div>
          <div className="crow">
            <p className="comcol1">Personalized Growth Strategies</p>
            <div className="comcol2"></div>
            <div className="comcol2">
              <img className="check" src={check} alt="check" />
            </div>
          </div>
          <div className="crow background">
            <p className="comcol1">Your Questions Answered</p>
            <div className="comcol2"></div>
            <div className="comcol2">
              <img className="check" src={check} alt="check" />
            </div>
          </div>
        </div>

        <h3>Year-End Financial Report</h3>

        <div className="comparison">
          <div className="crow cheader">
            <p className="comcol1" style={{ color: "white" }}>
              Benefit
            </p>
            <p className="comcol2 ssentials" style={{ color: "white" }}>
              E
            </p>
            <p className="comcol2 remium" style={{ color: "white" }}>
              P
            </p>
          </div>
          <div className="crow">
            <p className="comcol1">Clear Yearly Financial Picture</p>
            <div className="comcol2">
              <img className="check" src={check} alt="check" />
            </div>
            <div className="comcol2">
              <img className="check" src={check} alt="check" />
            </div>
          </div>
          <div className="crow background">
            <p className="comcol1">Comprehensive Financial Overview</p>
            <div className="comcol2">
              <img className="check" src={check} alt="check" />
            </div>
            <div className="comcol2">
              <img className="check" src={check} alt="check" />
            </div>
          </div>
          <div className="crow">
            <p className="comcol1">Streamlined Tax Filing</p>
            <div className="comcol2">
              <img className="check" src={check} alt="check" />
            </div>
            <div className="comcol2">
              <img className="check" src={check} alt="check" />
            </div>
          </div>
          <div className="crow background">
            <p className="comcol1">Identify Trends Year Over Year</p>
            <div className="comcol2">
              <img className="check" src={check} alt="check" />
            </div>
            <div className="comcol2">
              <img className="check" src={check} alt="check" />
            </div>
          </div>
          <div className="crow">
            <p className="comcol1">Prepare to Get Financing</p>
            <div className="comcol2">
              <img className="check" src={check} alt="check" />
            </div>
            <div className="comcol2">
              <img className="check" src={check} alt="check" />
            </div>
          </div>
        </div>

        <h3>1099 Preparation</h3>

        <div className="comparison">
          <div className="crow cheader">
            <p className="comcol1" style={{ color: "white" }}>
              Benefit
            </p>
            <p className="comcol2 ssentials" style={{ color: "white" }}>
              E
            </p>
            <p className="comcol2 remium" style={{ color: "white" }}>
              P
            </p>
          </div>
          <div className="crow">
            <p className="comcol1">Ensure Accuracy and Compliance</p>
            <div className="comcol2">
              <img className="check" src={check} alt="check" />
            </div>
            <div className="comcol2">
              <img className="check" src={check} alt="check" />
            </div>
          </div>
          <div className="crow background">
            <p className="comcol1">Stress-Free Contracting</p>
            <div className="comcol2">
              <img className="check" src={check} alt="check" />
            </div>
            <div className="comcol2">
              <img className="check" src={check} alt="check" />
            </div>
          </div>
          <div className="crow">
            <p className="comcol1">One-Step Filing</p>
            <div className="comcol2">
              <img className="check" src={check} alt="check" />
            </div>
            <div className="comcol2">
              <img className="check" src={check} alt="check" />
            </div>
          </div>
        </div>

        <h3>QuickBooks Online Training</h3>

        <div className="comparison">
          <div className="crow cheader">
            <p className="comcol1" style={{ color: "white" }}>
              Skills
            </p>
            <p className="comcol2 ssentials" style={{ color: "white" }}>
              E
            </p>
            <p className="comcol2 remium" style={{ color: "white" }}>
              P
            </p>
          </div>
          <div className="crow">
            <p className="comcol1">
              Create Invoices, Estimates, Bills, Purchase Orders, and More
            </p>
            <div className="comcol2">
              <img className="check" src={check} alt="check" />
            </div>
            <div className="comcol2">
              <img className="check" src={check} alt="check" />
            </div>
          </div>
          <div className="crow background">
            <p className="comcol1">Direct Receipt Submission</p>
            <div className="comcol2">
              <img className="check" src={check} alt="check" />
            </div>
            <div className="comcol2">
              <img className="check" src={check} alt="check" />
            </div>
          </div>
          <div className="crow">
            <p className="comcol1">On-Demand Reporting</p>
            <div className="comcol2">
              <img className="check" src={check} alt="check" />
            </div>
            <div className="comcol2">
              <img className="check" src={check} alt="check" />
            </div>
          </div>
        </div>

        <Link className="link" to="/contact">
          Get Started
        </Link>
      </div>
      <div className="section">
        <h2>Check out the many benefits.</h2>
        <div className="benefits-2">
          <Benefit
            title="Save Time"
            src={time}
            alt="clock with a counter-clockwise arrow as a frame and the minute hand and hour hands aligned in the shape of a check."
          />
          <Benefit title="Minimize Mistakes" src={perfect} alt="10/10" />
          {/* <Benefit title="Expert Advice" src={advice} alt="chat bubble" /> */}
          <Benefit
            title="Stress-Free Audits"
            src={audit}
            alt="magnifying glass spying a checkmark"
          />
          <Benefit
            title="Financial Guidance"
            src={guidance}
            alt="a bunch of three arrows stemming from one source."
          />
          <Benefit title="Improved Cash Flow" src={maximize} alt="" />
        </div>
        <Link className="link" to="/contact">
          Get Started
        </Link>
      </div>
      <div className="section background">
        <h2 style={{ color: "#886aff" }}>Just look at those prices.*</h2>
        <div className="pricing white">
          <h4>Premium Package</h4>
          <L2R1
            src={small}
            alt="small building"
            left="Small Business"
            right="$750"
          />
          <L2R1
            src={medium}
            alt="medium-sized building"
            left="Medium Business"
            right="$3,500"
          />
          <L2R1
            src={large}
            alt="large business"
            left="Large Business"
            right="$5,000"
          />
        </div>
        <div className="pricing">
          <h4>Essentials Package</h4>
          <L2R1
            src={small}
            alt="small building"
            left="Small Business"
            right="$250"
          />
          <L2R1
            src={medium}
            alt="medium-sized building"
            left="Medium Business"
            right="$1,725"
          />
          <L2R1
            src={large}
            alt="large business"
            left="Large Business"
            right="$3,500"
          />
        </div>
        <Link className="link" to="/contact">
          Get Started
        </Link>
        <span>*Prices represent a monthly recurring charge.</span>
      </div>
      <div className="section">
        <h2>View related resources.</h2>
        <FinanceTracker background />
      </div>
      <Footer />
    </div>
  );
};

export default Bookkeeping;
